<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title v-if="report">{{ report.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="color2 color2Text--text"
        fab small
        @click.stop="openDrawer"
      >
        <v-icon>fas fa-ellipsis-v</v-icon>
      </v-btn>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text>
      <v-data-table
        :headers="heads"
        :items="grouped"
        class="elevation-1"
        :options.sync="pagination"
        :search="search"
      >
        <template
          v-if="loading"
          v-slot:no-data
        >
          <v-progress-circular indeterminate color="color3"></v-progress-circular>
        </template>
        <template v-slot:[`item.player`]={item}>
          <v-avatar
            size="48"
            color="red"
          >
            <img :src="getPic(item.player)" :alt="`${item.player.firstName} ${item.player.lastName}`">
          </v-avatar>
          {{ item.player.firstName }} {{ item.player.lastName }}
        </template>
        <template v-slot:[`item.rated_On`]={item}>
          {{ dateDisplay(item.rated_On) }}
        </template>
        <template v-slot:[`item.birthYear`]={item}>
          {{ item.player.dob ? item.player.dob.slice(0,4) : 'NA' }}
        </template>
        <template v-slot:[`item.actions`]={item}>
          <div>
            <v-btn color="color3 color3Text--text" @click.stop="viewMore(item)" v-if="!report.props.includes('ntdp-admin')">more</v-btn>
            <v-btn
              color="color3"
              :to="{ name: 'player-profile', params: {playerProfileId: item.player.id}}"
              text
              v-if="report.props.includes('ntdp-admin')"
            >
              {{item.player.firstName}}'s Profile
            </v-btn>
          </div>
        </template>

      </v-data-table>
    </v-card-text>

    <display-settings-drawer
      ref="drawer"
      :report="report"
      :headers="headers"
      @refresh-click="loadData"
      @download-click="downloadReport"
    ></display-settings-drawer>
    <ntdp-drawer
      ref="ntdpDrawer"
      :player="selectedPlayer"
      :view="{admin: true}"
      :noBtn="true"
      @change="loadData"
    ></ntdp-drawer>

  </v-card>
</template>

<script>
import { firstBy } from 'thenby'
import { localDt } from '@/Filters'
import { ageDivision, indoorAgeDivision } from '@/classes/ProfileHelpers'

import toProperCase from '@/helpers/properCase'
import Papa from 'papaparse'
import moment from 'moment'
const DisplaySettingsDrawer = () => import('./DisplaySettingsDrawer.vue')
const NtdpDrawer = () => import('@/New/NtdpDrawer.vue')

export default {
  props: ['username', 'report', 'active'],
  data () {
    return {
      reportData: [],
      loading: false,
      headers: [],
      search: null,
      pagination: {},
      grouped: [],
      selectedPlayer: null
    }
  },
  computed: {
    heads () {
      return this.headers.filter(f => f.show)
    },
    downloadData () {
      return this.reportData.map(m => {
        return {
          Player: m.player.fullname,
          Rating: m.rating,
          Age: m.player.indoorAgeDivision,
          BirthYear: m.player.dob?.slice(0, 4),
          City: m.player.city,
          State: m.player.state,
          Scout: m.scout,
          'Rated On': m.rated_On,
          Comments: m.comments
        }
      })
    }
  },
  methods: {
    getPic (player) {
      return player.pic || (player.male ? 'https://vblimages.blob.core.windows.net/images/avatar-male.jpg' : 'https://vblimages.blob.core.windows.net/images/avatar-female.jpg')
    },
    dateDisplay (date) {
      return localDt(date)
    },
    loadData () {
      this.closeDrawer()
      this.loading = true
      this.$http.get(this.report.api)
        .then(r => {
          this.reportData = r.data
          this.reportData = this.reportData.sort(firstBy((a, b) => { return Object.keys(b).length - Object.keys(a).length }))
          this.processData()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    processData () {
      this.headers = []
      const row = this.reportData[0]
      const d = this.report.defaultColumns.map(m => m.toLowerCase())
      const hasDefaults = d && d.length > 0
      for (const key in row) {
        this.headers.push({
          text: toProperCase(key.replaceAll('_', ' ')),
          value: key,
          show: hasDefaults ? d.includes(key.toLowerCase()) : true
        })
      }
      this.headers.push({
        text: 'Birth Year',
        value: 'birthYear',
        show: hasDefaults ? d.includes('birthyear') : true
      })
      this.headers.push({
        text: 'Grad Year',
        value: 'player.gradYear',
        show: hasDefaults ? d.includes('gradyear') : true
      })
      this.headers.push({
        text: '',
        value: 'actions',
        show: true
      })
      this.reportData.forEach(d => {
        d.rated_On = new Date(d.rated_On)
        d.player.profilePic = d.player.pic
        d.player.fullname = `${d.player.firstName} ${d.player.lastName}`
        d.player.ageDivision = ageDivision(d.player.dob, d.player.gradYear)
        d.player.indoorAgeDivision = indoorAgeDivision(d.player.dob, d.player.gradYear)
        d.history = []
      })
      const sorted = this.reportData.sort((a, b) => { return b.rated_On.getTime() - a.rated_On.getTime() })
      const grouped = []
      sorted.forEach(row => {
        const x = grouped.find(f => f.player.id === row.player.id)
        if (x) {
          x.history.push(row)
        } else {
          grouped.push(row)
        }
      })
      this.grouped = grouped
      // this.openDrawer()
    },
    openDrawer () {
      this.$refs.drawer.open()
    },
    closeDrawer () {
      this.$refs.drawer && this.$refs.drawer.cancel()
    },
    createFileForDownload (data, name) {
      var opt = { header: true }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    downloadReport () {
      console.log('here')
      const today = moment().format('L').replaceAll('/', '_')
      var fileName = `${this.report.name.replace(/ /g, '_')}${today}.csv`
      var data = this.downloadData
      this.createFileForDownload(data, fileName)
    },
    viewMore (item) {
      this.selectedPlayer = item.player
      this.$nextTick(() => {
        this.$refs.ntdpDrawer.open()
      })
    }
  },
  components: {
    DisplaySettingsDrawer,
    NtdpDrawer
  },
  mounted () {
    this.loadData()
  }
}
</script>
