var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text","flat":""}},[(_vm.report)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.report.name))]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"color2 color2Text--text","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDrawer($event)}}},[_c('v-icon',[_vm._v("fas fa-ellipsis-v")])],1)],1),_c('loading-bar',{attrs:{"value":_vm.loading}}),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.heads,"items":_vm.grouped,"options":_vm.pagination,"search":_vm.search},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(_vm.loading)?{key:"no-data",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","color":"color3"}})]},proxy:true}:null,{key:"item.player",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"48","color":"red"}},[_c('img',{attrs:{"src":_vm.getPic(item.player),"alt":((item.player.firstName) + " " + (item.player.lastName))}})]),_vm._v(" "+_vm._s(item.player.firstName)+" "+_vm._s(item.player.lastName)+" ")]}},{key:"item.rated_On",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateDisplay(item.rated_On))+" ")]}},{key:"item.birthYear",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.player.dob ? item.player.dob.slice(0,4) : 'NA')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(!_vm.report.props.includes('ntdp-admin'))?_c('v-btn',{attrs:{"color":"color3 color3Text--text"},on:{"click":function($event){$event.stopPropagation();return _vm.viewMore(item)}}},[_vm._v("more")]):_vm._e(),(_vm.report.props.includes('ntdp-admin'))?_c('v-btn',{attrs:{"color":"color3","to":{ name: 'player-profile', params: {playerProfileId: item.player.id}},"text":""}},[_vm._v(" "+_vm._s(item.player.firstName)+"'s Profile ")]):_vm._e()],1)]}}],null,true)})],1),_c('display-settings-drawer',{ref:"drawer",attrs:{"report":_vm.report,"headers":_vm.headers},on:{"refresh-click":_vm.loadData,"download-click":_vm.downloadReport}}),_c('ntdp-drawer',{ref:"ntdpDrawer",attrs:{"player":_vm.selectedPlayer,"view":{admin: true},"noBtn":true},on:{"change":_vm.loadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }